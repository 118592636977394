body {
  margin: 0;
  background-color: #ececec !important;
}

.fade-in {
  animation: DefaultFadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: backwards;
}

@keyframes DefaultFadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
}