.testerly-logo-text {
  display: block;
  vertical-align: top;
  font-size: 2rem;
  font-family: "Tahoma", sans-serif;
  font-weight: 900;
  letter-spacing: .08rem;
  color: #4279a6;
  width: 15rem;
}

.testerly-logo-image {
  float: left;
  height: 40px;
  margin-top: 5px;
  width: 60px
}

.logo-container {
  padding: 1rem 0;
  min-height: 90px;
}

@media only screen and (max-width: 500px) {
  .testerly-logo-text {
    font-size: 1.2rem;
    width: 12rem;
  }

  .testerly-logo-image {
    height: 30px;
    margin-left: 10px;
    width: 50px;
  }

  .logo-container {
    min-height: 30px;
  }
}